@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

