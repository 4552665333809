@font-face {
    font-family: 'Dax Offc';
    src: url('../fonts/Daxoffc/DaxOffc.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Dax Offc';
    src: url('../fonts/Daxoffc/DaxOffc-Medi.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Dax Offc';
    src: url('../fonts/Daxoffc/DaxOffc-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

